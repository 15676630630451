import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'
import OptBtn from '../../../components/button'

const IndexPage = () => {
    return (
        <Layout lang="es">
            <SEO
                title="Optiwe - Integración genérica"
                description="Integra Optiwe con cualquier sistema para centralizar tu base de
                clientes y manejar eficientemente tus clientes potenciales."
                lang="es"
                locales={[
                    {
                        hreflang: 'es',
                        href: 'https://optiwe.com/es/integraciones/generica/'
                    }
                ]}
            />

            <main className="container-fluid ow-banner">
                {/*
                ^*********
                * HEADER *
                **********
                */}
                <Nav
                    lang="es"
                    showButtons
                />

                {/*
                ***************
                * MAIN BANNER *
                ***************
                * TODO: If the banner is used in another view, create a <Banner /> component
                */}
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                            <h1 className="ow-section--cards__title">
                                <span className="bold">
                                    Integra Optiwe con un <strong>sistema externo</strong>
                                </span>
                            </h1>
                            <p className="ow-section--cards__desc">
                                Optiwe es un CRM para los canales digitales como Whatsapp,
                                Instagram y Facebook. Una pregunta que usualmente se hacen
                                nuestros clientes es si puede ser integrado con sistemas externos o
                                CRMs del tipo in-house. La respuesta es sí, Optiwe ademas de
                                ofrecer integraciones con CRMs conocidos como Hubspot,
                                ofrece una integración genérica donde el sistema que se tiene que
                                adaptar, es el sistema externo.
                            </p>
                            <p className="ow-section--cards__desc">
                                Integrar Optiwe a un sistema externo puede traer una serie de
                                beneficios importantes para tu empresa. Aquí te presento algunas
                                de las ventajas que podrías aprovechar:
                            </p>

                            <ul>
                                <li>
                                    <p className="ow-section--cards__desc">
                                        Mejora en la atención al cliente: Optiwe te permitirá
                                        conectar con tus clientes a través de canales digitales
                                        populares como
                                        WhatsApp, Instagram y Facebook, lo que significa que podrás
                                        atender a tus clientes de manera más rápida y eficiente.
                                        Con la integración de Optiwe, podrás responder a todas las
                                        consultas de tus clientes desde una única plataforma,
                                        lo que simplificará la gestión de tu atención al cliente.
                                    </p>
                                </li>

                                <li>
                                    <p className="ow-section--cards__desc">
                                        Incremento en las ventas: Al mejorar la atención al cliente,
                                        podrás incrementar las ventas de tu empresa. Al utilizar
                                        Optiwe para centralizar la atención al cliente, podrás
                                        responder a las consultas de tus clientes de manera más
                                        rápida y eficiente, lo que aumentará la satisfacción de tus
                                        clientes y, por lo tanto, las posibilidades de que realicen
                                        una compra.
                                    </p>
                                </li>

                                <li>
                                    <p className="ow-section--cards__desc">
                                        Ahorro de tiempo y recursos: La integración de Optiwe con
                                        un sistema externo te permitirá que la gestión de los
                                        clientes sea más efectiva y eficiente, lo que reducirá el
                                        tiempo que dedicas a esta tarea y los recursos que debes
                                        invertir en ella.
                                    </p>
                                </li>

                                <li>
                                    <p className="ow-section--cards__desc">
                                        Información detallada de tus clientes: Al utilizar Optiwe
                                        en conjunto a un sistema externo, podrás obtener
                                        información detallada de tus clientes, lo que te permitirá
                                        conocer mejor sus necesidades y ofrecerles una atención más
                                        personalizada y adaptada a sus requerimientos.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-5 mb-5"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <OptBtn isLink rounded small href="/es/crear-cuenta/">
                            Regístrate, es grátis!
                        </OptBtn>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage
